import { RevisionId } from "../model";

export enum CoverLetterEventKind {
  Ok = "Ok",
  Err = "Err",
}

export enum CoverLetterErrorKind {
  // These are the errors that the server sends as regular events.
  // This should be kept in sync with the "CoverLetterError" type in the backend.
  NotFound = "NotFound",
  TooManyAnonymousRequests = "TooManyAnonymousRequests",
  GenerationFailed = "GenerationFailed",
  NeitherJobAdvertNorResumeSpecified = "NeitherJobAdvertNorResumeSpecified",

  // These are all other errors, for example network errors or internal server errors.
  Other = "Other",
}

export type CoverLetterError = {
  kind: CoverLetterErrorKind;
};

export type CoverLetterEvent =
  | { kind: CoverLetterEventKind.Ok; chunk: string }
  | { kind: CoverLetterEventKind.Err; error: CoverLetterError };

export async function* getCoverLetter(
  revisionId: RevisionId,
): AsyncGenerator<CoverLetterEvent, void, void> {
  const url = `/api/cover-letter/${revisionId.revisionId}`;
  const eventSource = new EventSource(url);

  const queue: (CoverLetterEvent | "end")[] = [];
  // We wrap the resolve queue function into an object because of the no-loop-func eslint rule.
  // A flattened version declared with let would work, too, but result in a warning.
  const resolveQueue: { resolveQueue: null | (() => void) } = {
    resolveQueue: null,
  };

  eventSource.onmessage = (event) => {
    queue.push({ kind: CoverLetterEventKind.Ok, chunk: event.data });
    if (resolveQueue.resolveQueue != null) {
      resolveQueue.resolveQueue();
      resolveQueue.resolveQueue = null;
    }
  };
  eventSource.onerror = (event) => {
    const error =
      "data" in event && typeof event.data === "string"
        ? (JSON.parse(event.data) as CoverLetterError)
        : { kind: CoverLetterErrorKind.Other };

    queue.push({ kind: CoverLetterEventKind.Err, error });
    eventSource.close();
    if (resolveQueue.resolveQueue) {
      resolveQueue.resolveQueue();
      resolveQueue.resolveQueue = null;
    }
  };
  eventSource.addEventListener("end", (event) => {
    queue.push("end");
    eventSource.close();
    if (resolveQueue.resolveQueue != null) {
      resolveQueue.resolveQueue();
      resolveQueue.resolveQueue = null;
    }
  });

  while (true) {
    let event: CoverLetterEvent | "end" | undefined;
    while ((event = queue.shift())) {
      if (event === "end") {
        return;
      }

      yield event;
    }

    await new Promise<void>((resolve) => {
      resolveQueue.resolveQueue = resolve;
    });
  }
}
