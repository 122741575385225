import { useEffect, useState, useCallback } from "react";
import { useLocation } from "wouter";
import {
  CoverLetterErrorKind,
  CoverLetterEventKind,
  getCoverLetter,
} from "./api/cover-letter";
import { LetterHead } from "./letter-head";
import { ApplicationParameters, Uuid, UserType } from "./model";
import useDeviceType from "./use-device-type";
import { DownloadLink, DownloadLinkTarget } from "./download-link";
import { TierOverview, TierKind } from "./plans";
import { ResumeLayout } from "./bindings/ResumeLayout";

type CoverLetterProps = {
  revisionId: Uuid | null;
  setShowError: (show: boolean) => void;
  layout: ResumeLayout;
  setShowPlansPopup: (show: boolean) => void;
  pushApplicationParameters:
    | null
    | ((update: (old: ApplicationParameters) => ApplicationParameters) => void);
};

const fadeOutStyle: React.CSSProperties = {
  content: "''",
  width: "100%",
  height: "100%",
  position: "absolute",
  left: 0,
  top: 0,
  background: "linear-gradient(transparent 0, white)",
};

export function CoverLetter({
  revisionId,
  layout = "dualColumn",
  setShowError,
  setShowPlansPopup,
  pushApplicationParameters,
}: CoverLetterProps): JSX.Element {
  const [coverLetterText, setCoverLetterText] = useState<string>("");

  const [coverLetterTextKey, setCoverLetterTextKey] = useState<string>("");

  const [fetchInProgress, setFetchInProgress] = useState<{
    revisionId: Uuid;
    cancelSignal: () => void;
  } | null>(null);

  const [isRateLimited, setIsRateLimited] = useState<boolean>(false);

  const [location, setLocation] = useLocation();

  useEffect(() => {
    if (revisionId == null) {
      setCoverLetterText("");
      return;
    }

    // If we're already fetching the current revision, we don't need to do anything.
    if (fetchInProgress != null && fetchInProgress.revisionId === revisionId) {
      return;
    }

    if (fetchInProgress != null) {
      fetchInProgress.cancelSignal();
      setFetchInProgress(null);
    }

    const cancelSignal = new Promise<"cancel">((resolve) => {
      setFetchInProgress({ cancelSignal: () => resolve("cancel"), revisionId });
    });

    (async () => {
      const coverLetterEvents = getCoverLetter({ revisionId: revisionId });

      // At first we're just buffering the incoming data in this variable instead of showing it immediately.
      // Only if the text we're receiving differs from the existing text we're updating the state we're showing.
      // This way, we're avoiding unnecessary re-renders that look like flickering.
      //
      // The variable is wrapped inside an object to get around the no-loop-func rule of eslint.
      const newCoverLetterText: { value: string } = { value: "" };

      while (true) {
        const nextEvent = await Promise.race([
          coverLetterEvents.next(),
          cancelSignal,
        ]);
        if (nextEvent === "cancel") {
          return;
        }
        if (nextEvent.done) {
          if (pushApplicationParameters) {
            pushApplicationParameters((old) => ({
              ...old,
              coverLetterText: newCoverLetterText.value,
            }));
          }
          setIsRateLimited(false);
          return;
        }
        const event = nextEvent.value;

        switch (event.kind) {
          case CoverLetterEventKind.Ok: {
            newCoverLetterText.value += event.chunk;
            setCoverLetterText((prevText) => {
              if (prevText.startsWith(newCoverLetterText.value)) {
                return prevText;
              } else {
                return newCoverLetterText.value;
              }
            });
            break;
          }
          case CoverLetterEventKind.Err: {
            switch (event.error.kind) {
              case CoverLetterErrorKind.NotFound: {
                console.error("Cover letter not found");
                // TODO: Perhaps we should redirect to a 404 page or the home page.
                setShowError(true);
                return;
              }
              case CoverLetterErrorKind.TooManyAnonymousRequests: {
                console.error("Too many requests without subscribed user");
                setIsRateLimited(true);
                return;
              }
              case CoverLetterErrorKind.GenerationFailed: {
                setShowError(true);
                return;
              }
              case CoverLetterErrorKind.Other: {
                setShowError(true);
                return;
              }
              case CoverLetterErrorKind.NeitherJobAdvertNorResumeSpecified: {
                return;
              }
              default: {
                const exhaustive: never = event.error.kind;
                throw new Error(`Unhandled: ${exhaustive}`);
              }
            }
          }
        }
      }
    })();
  }, [
    revisionId,
    fetchInProgress,
    setShowError,
    location,
    setLocation,
    pushApplicationParameters,
  ]);

  const isMobile = useDeviceType();

  const parentStyle: React.CSSProperties = {
    paddingLeft: isMobile ? 8 : "25mm",
    paddingRight: isMobile ? 8 : "25mm",
    position: "relative",
    width: "100%",
  };

  const handleBlur = (event: React.FocusEvent<HTMLDivElement>) => {
    const newText = event.currentTarget.innerText;
    if (newText !== coverLetterText) {
      setCoverLetterText(newText);
      if (pushApplicationParameters) {
        pushApplicationParameters((old) => ({
          ...old,
          coverLetterText: newText,
        }));
      }
    }
  };

  const getHash = useCallback(async (text: string): Promise<string> => {
    const encoder = new TextEncoder();
    const data = encoder.encode(text);
    const hashBuffer = await crypto.subtle.digest("SHA-256", data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    return hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");
  }, []);

  useEffect(() => {
    getHash(coverLetterText).then(setCoverLetterTextKey);
  }, [coverLetterText, getHash]);

  const coverLetterLines = coverLetterText.split("\n");
  return (
    <>
      <div style={{ fontFamily: "'Montserrat', sans-serif" }}>
        <LetterHead
          revisionId={revisionId}
          setShowError={setShowError}
          pushApplicationParameters={pushApplicationParameters}
        />
        <div style={{ height: 32 }}></div>
        <div style={parentStyle}>
          <div
            className="multi-page"
            contentEditable={true}
            suppressContentEditableWarning={true}
            onBlur={handleBlur}
            key={coverLetterTextKey}
          >
            {isRateLimited && <div style={fadeOutStyle}></div>}
            {coverLetterLines.flatMap((line, index) => {
              if (line === "") {
                return [];
              } else {
                return [<p key={index}>{line}</p>];
              }
            })}
            {
              // TODO: Uncomment this when we have tweaked the size properly.
              // revisionId != null && (
              // 	<img
              // 		src={`/api/signature/${revisionId}`}
              // 		alt=""
              // 		className="portrait"
              // 		style={{
              // 			width: "30mm",
              // 			height: "auto",
              // 		}}
              // 	/>
              // )
            }
          </div>
        </div>
      </div>
      {isRateLimited && (
        <div style={{ ...parentStyle, paddingTop: "32px" }}>
          <div
            className="row justify-content-center"
            style={{ fontFamily: "" }}
          >
            <TierOverview
              tierKind={TierKind.Professional}
              displayMode="page"
              userType={UserType.Anonymous}
              title={"Weiter mit Bewerbungshelfer Professional"}
            />
          </div>
        </div>
      )}
      {!isRateLimited && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <DownloadLink
            revisionId={revisionId}
            layout={layout}
            target={DownloadLinkTarget.CoverLetter}
            setShowPlansPopup={setShowPlansPopup}
          />
        </div>
      )}
    </>
  );
}
