import React, { useState } from "react";
import { Spinner } from "./spinner";
import placeholderImage from "./media/businessman-icon.svg";
import { NO_PRINT } from "./styles";

export function Headshot({
  revisionId,
  style,
}: {
  revisionId: string | null;
  style: React.CSSProperties;
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  if (revisionId == null) {
    return null;
  }

  const placeholderImageElement = (
    <img src={placeholderImage} alt="Headshot placeholder" style={style} />
  );

  let placeholder = null;
  if (isLoading) {
    placeholder = (
      <div className={NO_PRINT}>
        <Spinner />
        {placeholderImageElement}
      </div>
    );
  } else if (hasError) {
    placeholder = <div className={NO_PRINT}>{placeholderImageElement}</div>;
  }
  return (
    <div style={{ position: "relative" }}>
      {(isLoading || hasError) && placeholder}
      <img
        src={`/api/headshot/${revisionId}`}
        alt=""
        style={{
          ...style,
          display: isLoading ? "none" : "block",
        }}
        onError={(e) => {
          setIsLoading(false);
          setHasError(true);
        }}
        onLoad={() => {
          setIsLoading(false);
        }}
      />
    </div>
  );
}
