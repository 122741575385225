import { useCookies } from "react-cookie";
import { UserType } from "./model";

function tryParseUserType(value: string): UserType | null {
  switch (value) {
    case "Anonymous":
      return UserType.Anonymous;
    case "Standard":
      return UserType.Standard;
    case "Subscribed":
      return UserType.Subscribed;
    default:
      return null;
  }
}

const USER_COOKIE_NAME = "user";

export const useUserType = (): UserType | null => {
  const [cookies] = useCookies([USER_COOKIE_NAME]);

  const userCookie = cookies.user;
  if (!userCookie) {
    return null;
  }

  return tryParseUserType(userCookie);
};
