import { RevisionId, ApplicationParameters } from "../model";
import { JsonResume } from "../bindings/JsonResume";

export async function getJsonResume(
  revisionId: RevisionId,
): Promise<JsonResume> {
  const response = await fetch(`/api/json-resume/${revisionId.revisionId}`);

  if (!response.ok) {
    throw new Error("Failed to fetch json resume:" + response.statusText);
  }

  return await response.json();
}

export async function improveJsonResume(
  applicationParameters: ApplicationParameters,
): Promise<JsonResume> {
  const response = await fetch(`/api/json-resume/improve`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(applicationParameters),
  });

  if (!response.ok) {
    throw new Error("Failed to improve json resume:" + response.statusText);
  }

  return await response.json();
}
