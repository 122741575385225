import React, { useEffect, useState, useCallback } from "react";
import { getJsonResume } from "./api/json-resume";
import { ApplicationParameters } from "./model";
import { Basics } from "./bindings/Basics";
import { Education } from "./bindings/Education";
import { JsonResume } from "./bindings/JsonResume";
import { Skills } from "./bindings/Skills";
import { Skill } from "./bindings/Skill";
import { Work } from "./bindings/Work";
import { Volunteer } from "./bindings/Volunteer";
import { Certificate } from "./bindings/Certificate";
import { PersonalInfo } from "./bindings/PersonalInfo";
import { ResumeDate } from "./bindings/ResumeDate";
import { ResumeLayout } from "./bindings/ResumeLayout";
import { Spinner } from "./spinner";
import {
  EditableResumeDate,
  EditableResumeDateRange,
} from "./editable-resume-date";
import { EditableSpan, EditableEmail } from "./editable";
import { AdjustableFontSizeContainer } from "./adjustable-font-size";
import { interleave } from "./utils";
// TODO: do we want to let each template specify this?
// In this case, we should move it to the style constants in this module.
import {
  PAGE_CONTAINER_MARGIN_TOP,
  PAGE_CONTAINER_MARGIN_BOTTOM,
  A4_PAGE_HEIGHT_INNER_PX,
} from "./internal-print";
import { useApplicationTitle } from "./application-title";
import { Headshot } from "./headshot";

import { HIDE_AT_PAGE_BREAK, NO_PRINT, MULTI_PAGE } from "./styles";

type Section =
  | "NameHeading"
  | "ContactsElement"
  | "PersonalInfoElement"
  | "SkillsSection"
  | "WorkSection"
  | "EducationSection"
  | "VolunteerSection"
  | "CertificateSection";

type SectionData = {
  component: (props: SectionProps) => JSX.Element;
  shouldRender: (jsonResume: JsonResume) => boolean;
};

type SectionProps = {
  jsonResume: JsonResume;
  setJsonResume: (newJsonResume: JsonResume) => void;
  layout: ResumeLayout;
};

const sectionsMap: Record<Section, SectionData> = {
  NameHeading: {
    component: ({ jsonResume, setJsonResume, layout }: SectionProps) => (
      <NameHeading
        jsonResume={jsonResume}
        setJsonResume={setJsonResume}
        layout={layout}
      />
    ),
    shouldRender: (jsonResume: JsonResume) => true,
  },
  ContactsElement: {
    component: ({ jsonResume, setJsonResume, layout }: SectionProps) => (
      <ContactsElement
        jsonResume={jsonResume}
        setJsonResume={setJsonResume}
        layout={layout}
      />
    ),
    shouldRender: (jsonResume: JsonResume) =>
      jsonResume.basics.email !== "" ||
      jsonResume.basics.phone !== "" ||
      jsonResume.basics.location.city !== "",
  },
  PersonalInfoElement: {
    component: ({ jsonResume, setJsonResume, layout }: SectionProps) => (
      <PersonalInfoElement
        jsonResume={jsonResume}
        setJsonResume={setJsonResume}
        layout={layout}
      />
    ),
    shouldRender: (jsonResume: JsonResume) =>
      jsonResume.personalInfo.birthDate !== null ||
      jsonResume.personalInfo.birthPlace !== "" ||
      jsonResume.personalInfo.nationality !== "" ||
      jsonResume.personalInfo.maritalStatus !== "" ||
      jsonResume.personalInfo.children !== "",
  },
  SkillsSection: {
    component: ({ jsonResume, setJsonResume, layout }: SectionProps) => (
      <SkillsSection
        jsonResume={jsonResume}
        setJsonResume={setJsonResume}
        layout={layout}
      />
    ),
    shouldRender: (jsonResume: JsonResume) =>
      jsonResume.skills.software.length !== 0 ||
      jsonResume.skills.driversLicence !== "" ||
      jsonResume.skills.languages.length !== 0 ||
      jsonResume.skills.characterTraits.length !== 0 ||
      jsonResume.skills.interests.length !== 0,
  },
  WorkSection: {
    component: ({ jsonResume, setJsonResume, layout }: SectionProps) => (
      <WorkSection
        jsonResume={jsonResume}
        setJsonResume={setJsonResume}
        layout={layout}
      />
    ),
    shouldRender: (jsonResume: JsonResume) => jsonResume.work.length !== 0,
  },
  EducationSection: {
    component: ({ jsonResume, setJsonResume, layout }: SectionProps) => (
      <EducationSection
        jsonResume={jsonResume}
        setJsonResume={setJsonResume}
        layout={layout}
      />
    ),
    shouldRender: (jsonResume: JsonResume) => jsonResume.education.length !== 0,
  },
  VolunteerSection: {
    component: ({ jsonResume, setJsonResume, layout }: SectionProps) => (
      <VolunteerSection
        jsonResume={jsonResume}
        setJsonResume={setJsonResume}
        layout={layout}
      />
    ),
    shouldRender: (jsonResume: JsonResume) => jsonResume.volunteer.length !== 0,
  },
  CertificateSection: {
    component: ({ jsonResume, setJsonResume, layout }: SectionProps) => (
      <CertificateSection
        jsonResume={jsonResume}
        setJsonResume={setJsonResume}
        layout={layout}
      />
    ),
    shouldRender: (jsonResume: JsonResume) =>
      jsonResume.certificates.length !== 0,
  },
};

enum RelativePosition {
  Before = "before",
  After = "after",
}

type SoftwareElementStyle = {
  container: React.CSSProperties;
  title: React.CSSProperties;
  contentContainer: React.CSSProperties;
};

type LanguagesElementStyle = {
  container: React.CSSProperties;
  title: React.CSSProperties;
  contentContainer: React.CSSProperties;
};

type SkillElementStyle = {
  container: React.CSSProperties;
};

type ContactsElementStyle = {
  container: React.CSSProperties;
  title: React.CSSProperties;
  contentContainer: React.CSSProperties;
  row: React.CSSProperties;
  icons: React.CSSProperties;
  separator: JSX.Element | null;
  locationElements: React.CSSProperties;
};

type PersonalInfoElementStyle = {
  container: React.CSSProperties;
  title: React.CSSProperties;
  contentContainer: React.CSSProperties;
};

type SkillsSectionStyle = {
  skill: React.CSSProperties;
  title: React.CSSProperties;
  separator: JSX.Element;
};

type WorkElementStyle = {
  container: React.CSSProperties;
  header: React.CSSProperties;
  titleGroup: React.CSSProperties;
  position: React.CSSProperties;
  company: React.CSSProperties;
  location: React.CSSProperties;
  date: React.CSSProperties;
  highlights: React.CSSProperties;
};

type EducationElementStyle = {
  container: React.CSSProperties;
  header: React.CSSProperties;
  titleGroup: React.CSSProperties;
  degree: React.CSSProperties;
  institution: React.CSSProperties;
  date: React.CSSProperties;
  courses: React.CSSProperties;
};

type VolunteerElementStyle = {
  container: React.CSSProperties;
  header: React.CSSProperties;
  titleGroup: React.CSSProperties;
  position: React.CSSProperties;
  organization: React.CSSProperties;
  location: React.CSSProperties;
  date: React.CSSProperties;
  highlights: React.CSSProperties;
};

type CertificateElementStyle = {
  container: React.CSSProperties;
  header: React.CSSProperties;
  titleGroup: React.CSSProperties;
  name: React.CSSProperties;
  institution: React.CSSProperties;
  date: React.CSSProperties;
  highlights: React.CSSProperties;
};

type NameHeadingStyle = {
  container: React.CSSProperties;
  name: React.CSSProperties;
  firstName: React.CSSProperties;
  lastName: React.CSSProperties;
  caption: React.CSSProperties;
};

type DriversLicenceElementStyle = {
  container: React.CSSProperties;
  title: React.CSSProperties;
  contentContainer: React.CSSProperties;
};

type CharacterTraitsElementStyle = {
  container: React.CSSProperties;
  title: React.CSSProperties;
  contentContainer: React.CSSProperties;
};

type InterestsElementStyle = {
  container: React.CSSProperties;
  title: React.CSSProperties;
  contentContainer: React.CSSProperties;
};

type ResumeComponentStyles = {
  driversLicenceElement: DriversLicenceElementStyle;
  characterTraitsElement: CharacterTraitsElementStyle;
  interestsElement: InterestsElementStyle;
  softwareElement: SoftwareElementStyle;
  languagesElement: LanguagesElementStyle;
  skillElement: SkillElementStyle;
  contactsElement: ContactsElementStyle;
  personalInfoElement: PersonalInfoElementStyle;
  skillsSection: SkillsSectionStyle;
  workElement: WorkElementStyle;
  educationElement: EducationElementStyle;
  volunteerElement: VolunteerElementStyle;
  certificateElement: CertificateElementStyle;
  nameHeading: NameHeadingStyle;
  workSection: WorkSectionStyle;
  educationSection: EducationSectionStyle;
  volunteerSection: VolunteerSectionStyle;
  certificateSection: CertificateSectionStyle;
};

type CertificateSectionStyle = {
  separator: JSX.Element;
};

type VolunteerSectionStyle = {
  separator: JSX.Element;
};

type WorkSectionStyle = {
  separator: JSX.Element;
};

type EducationSectionStyle = {
  separator: JSX.Element;
};

type GlobalStyles = {
  resume: React.CSSProperties;
  sidebar: React.CSSProperties;
  sidebarInner: React.CSSProperties;
  content: React.CSSProperties;
  contentInner: React.CSSProperties;
  sectionHeader: React.CSSProperties;
  portrait: React.CSSProperties;
};

type ResumeStyles = ResumeComponentStyles & GlobalStyles;

export const styleConstants = {
  fontSize: {
    small: "10px",
    normal: "12px",
    large: "14px",
    XLarge: "18px",
    XXLarge: "24px",
    XXXLargePx: 36,
    XXXXLargePx: 48,
  },
  spacing: {
    small: "8px",
    normal: "16px",
    normalLarge: "24px",
    large: "32px",
    extraLarge: "48px",
  },
  fontWeight: {
    normal: 400,
    bold: 500,
  },
  letterSpacing: {
    normal: "0.66666px",
    wide: "3px",
    extraWide: "4px",
    superWide: "8px",
  },
  lineHeight: 1.666666,
  colors: {
    primary: "#000",
    secondary: "#F8F3EF",
  },
};

const intraSectionSeparator: JSX.Element = (
  <div style={{ height: styleConstants.spacing.normal }}></div>
);

const layoutConstants: Record<ResumeLayout, { [key: string]: string }> = {
  singleColumn: {
    gridColumns: "repeat(3, 1fr)",
  },
  dualColumn: {
    sidebarWidth: "33.333333%",
    contentWidth: "66.6666%",
  },
};

const globalStyles: GlobalStyles = {
  resume: {
    letterSpacing: styleConstants.letterSpacing.normal,
    margin: 0,
    padding: 0,
    width: "100%",
    fontFamily: '"Montserrat", sans-serif',
    WebkitFontSmoothing: "antialiased",
    MozOsxFontSmoothing: "grayscale",
    fontSmooth: "antialiased",
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
    fontSize: styleConstants.fontSize.normal,
    overflowWrap: "break-word",
  },
  sidebar: {
    backgroundColor: styleConstants.colors.secondary,
    minWidth: layoutConstants["dualColumn"].sidebarWidth,
    fontSize: styleConstants.fontSize.normal,
  },
  sidebarInner: {
    paddingLeft: styleConstants.spacing.large,
    paddingRight: styleConstants.spacing.large,
    // We rely on margin collaps of this margin with the page containers margin here.
    // I would like to get rid of this, but it works.
    marginTop: PAGE_CONTAINER_MARGIN_TOP,
    marginBottom: PAGE_CONTAINER_MARGIN_BOTTOM,
  },
  content: {
    minWidth: layoutConstants["dualColumn"].contentWidth,
  },
  contentInner: {
    paddingLeft: styleConstants.spacing.extraLarge,
    paddingRight: styleConstants.spacing.extraLarge,
    // We rely on margin collaps of this margin with the page containers margin here.
    // I would like to get rid of this, but it works.
    marginTop: PAGE_CONTAINER_MARGIN_TOP,
    marginBottom: PAGE_CONTAINER_MARGIN_BOTTOM,
  },
  sectionHeader: {
    fontSize: styleConstants.fontSize.large,
    textTransform: "uppercase",
    letterSpacing: styleConstants.letterSpacing.extraWide,
    paddingBottom: styleConstants.spacing.small,
  },
  portrait: {
    width: "100%",
    height: "auto",
  },
};

const defaultComponentStyles: ResumeComponentStyles = {
  driversLicenceElement: {
    container: {},
    title: {
      fontSize: styleConstants.fontSize.large,
      marginBottom: styleConstants.spacing.small,
    },
    contentContainer: {},
  },
  characterTraitsElement: {
    container: {},
    title: {
      fontSize: styleConstants.fontSize.large,
      marginBottom: styleConstants.spacing.small,
    },
    contentContainer: {},
  },
  interestsElement: {
    container: {},
    title: {
      fontSize: styleConstants.fontSize.large,
      marginBottom: styleConstants.spacing.small,
    },
    contentContainer: {},
  },
  softwareElement: {
    container: {},
    title: {
      fontSize: styleConstants.fontSize.large,
      marginBottom: styleConstants.spacing.small,
    },
    contentContainer: {},
  },
  languagesElement: {
    container: { lineHeight: styleConstants.lineHeight },
    title: {
      fontSize: styleConstants.fontSize.large,
      marginBottom: styleConstants.spacing.small,
    },
    contentContainer: {},
  },
  skillElement: {
    container: {},
  },
  contactsElement: {
    container: {},
    title: {
      textTransform: "uppercase",
      letterSpacing: styleConstants.letterSpacing.wide,
      borderBottom: `1px solid ${styleConstants.colors.primary}`,
      paddingTop: 0,
      paddingBottom: styleConstants.spacing.small,
      fontSize: styleConstants.fontSize.large,
    },
    contentContainer: {},
    row: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
      lineHeight: styleConstants.lineHeight,
      marginBottom: styleConstants.spacing.small,
    },
    icons: { marginRight: styleConstants.spacing.small, display: "inline" },
    separator: null,
    locationElements: { display: "inline" },
  },
  personalInfoElement: {
    container: {},
    title: {
      textTransform: "uppercase",
      letterSpacing: styleConstants.letterSpacing.wide,
      paddingTop: 0,
      paddingBottom: styleConstants.spacing.small,
      fontSize: styleConstants.fontSize.large,
    },
    contentContainer: {},
  },
  skillsSection: {
    skill: { lineHeight: styleConstants.lineHeight },
    title: {
      textTransform: "uppercase",
      letterSpacing: styleConstants.letterSpacing.wide,
      paddingTop: 0,
      paddingBottom: styleConstants.spacing.small,
      fontSize: styleConstants.fontSize.large,
    },
    separator: intraSectionSeparator,
  },
  workElement: {
    container: {
      pageBreakInside: "avoid",
      lineHeight: styleConstants.lineHeight,
    },
    header: {},
    titleGroup: {},
    position: {
      fontSize: styleConstants.fontSize.large,
      marginBottom: 0,
      fontWeight: styleConstants.fontWeight.bold,
    },
    company: {
      display: "inline",
    },
    location: {
      display: "inline",
    },
    highlights: {
      marginBottom: 0,
      margin: 0,
    },
    date: {},
  },
  educationElement: {
    container: {
      pageBreakInside: "avoid",
      lineHeight: styleConstants.lineHeight,
    },
    header: {},
    titleGroup: {},
    degree: {
      fontSize: styleConstants.fontSize.large,
      marginBottom: 0,
      fontWeight: styleConstants.fontWeight.bold,
    },
    institution: {
      display: "inline",
    },
    date: {},
    courses: {
      marginBottom: 0,
    },
  },
  volunteerElement: {
    container: {
      pageBreakInside: "avoid",
      lineHeight: styleConstants.lineHeight,
    },
    header: {},
    titleGroup: {},
    position: {
      fontSize: styleConstants.fontSize.large,
      marginBottom: 0,
      fontWeight: styleConstants.fontWeight.bold,
    },
    organization: {
      display: "inline",
    },
    location: {
      display: "inline",
    },
    highlights: {
      marginBottom: 0,
      margin: 0,
    },
    date: {},
  },
  certificateElement: {
    container: {
      pageBreakInside: "avoid",
      lineHeight: styleConstants.lineHeight,
    },
    header: {},
    titleGroup: {},
    name: {
      fontSize: styleConstants.fontSize.large,
      marginBottom: 0,
      fontWeight: styleConstants.fontWeight.bold,
    },
    institution: {
      display: "inline",
    },
    date: {},
    highlights: {
      marginBottom: 0,
      margin: 0,
    },
  },
  nameHeading: {
    container: {
      display: "flex",
      flexDirection: "column",
      textTransform: "uppercase",
      letterSpacing: styleConstants.letterSpacing.superWide,
    },
    firstName: {},
    lastName: {},
    name: {
      letterSpacing: styleConstants.letterSpacing.superWide,
      fontWeight: styleConstants.fontWeight.normal,
      whiteSpace: "nowrap",
    },
    caption: {
      fontSize: styleConstants.fontSize.XLarge,
      letterSpacing: styleConstants.letterSpacing.superWide,
      fontWeight: styleConstants.fontWeight.bold,
      overflowWrap: "break-word",
    },
  },
  workSection: {
    separator: intraSectionSeparator,
  },
  educationSection: {
    separator: intraSectionSeparator,
  },
  volunteerSection: {
    separator: intraSectionSeparator,
  },
  certificateSection: {
    separator: intraSectionSeparator,
  },
};

const resumeStyles: { [key in ResumeLayout]: ResumeStyles } = {
  singleColumn: {
    ...globalStyles,
    sectionHeader: {
      ...globalStyles.sectionHeader,
    },
    ...defaultComponentStyles,
    softwareElement: {
      ...defaultComponentStyles.softwareElement,
      contentContainer: {
        display: "grid",
        gridTemplateColumns: layoutConstants["singleColumn"].gridColumns,
        gap: styleConstants.spacing.small,
      },
    },
    languagesElement: {
      ...defaultComponentStyles.languagesElement,
      contentContainer: {
        display: "grid",
        gridTemplateColumns: layoutConstants["singleColumn"].gridColumns,
        gap: styleConstants.spacing.small,
      },
    },
    contactsElement: {
      ...defaultComponentStyles.contactsElement,
      contentContainer: {
        textAlign: "center",
      },
      icons: {
        display: "none",
      },
      title: {
        display: "none",
      },
      row: {
        display: "inline",
      },
      separator: <span> | </span>,
      container: {
        ...defaultComponentStyles.contactsElement.container,
        marginTop: "-" + styleConstants.spacing.normal,
        marginBottom: "-" + styleConstants.spacing.normal,
      },
    },
    personalInfoElement: {
      ...defaultComponentStyles.personalInfoElement,
      contentContainer: {
        display: "grid",
        gridTemplateColumns: "repeat(2, 1fr)",
        gap: styleConstants.spacing.small,
      },
      title: {
        ...defaultComponentStyles.personalInfoElement.title,
      },
    },
    driversLicenceElement: {
      ...defaultComponentStyles.driversLicenceElement,
      contentContainer: {
        display: "grid",
        gridTemplateColumns: layoutConstants["singleColumn"].gridColumns,
        gap: styleConstants.spacing.small,
      },
    },
    characterTraitsElement: {
      ...defaultComponentStyles.characterTraitsElement,
      contentContainer: {
        display: "grid",
        gridTemplateColumns: layoutConstants["singleColumn"].gridColumns,
        gap: styleConstants.spacing.small,
      },
    },
    interestsElement: {
      ...defaultComponentStyles.interestsElement,
      contentContainer: {
        display: "grid",
        gridTemplateColumns: layoutConstants["singleColumn"].gridColumns,
        gap: styleConstants.spacing.small,
      },
    },
    workElement: {
      ...defaultComponentStyles.workElement,
      header: {
        position: "relative",
        paddingRight: "150px",
        marginBottom: styleConstants.spacing.small,
      },
      titleGroup: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "baseline",
      },
      position: {
        fontSize: styleConstants.fontSize.large,
        fontWeight: styleConstants.fontWeight.bold,
        marginBottom: 0,
        marginRight: "0.5em",
      },
      date: {
        position: "absolute",
        top: 0,
        right: 0,
        width: "140px",
        textAlign: "right",
      },
    },
    educationElement: {
      ...defaultComponentStyles.educationElement,
      header: {
        position: "relative",
        paddingRight: "150px",
        marginBottom: styleConstants.spacing.small,
      },
      titleGroup: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "baseline",
      },
      degree: {
        fontSize: styleConstants.fontSize.large,
        fontWeight: styleConstants.fontWeight.bold,
        marginBottom: 0,
        marginRight: "0.5em",
      },
      date: {
        position: "absolute",
        top: 0,
        right: 0,
        width: "140px",
        textAlign: "right",
      },
    },
    volunteerElement: {
      ...defaultComponentStyles.volunteerElement,
      header: {
        position: "relative",
        paddingRight: "150px",
        marginBottom: styleConstants.spacing.small,
      },
      titleGroup: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "baseline",
      },
      position: {
        fontSize: styleConstants.fontSize.large,
        fontWeight: styleConstants.fontWeight.bold,
        marginBottom: 0,
        marginRight: "0.5em",
      },
      date: {
        position: "absolute",
        top: 0,
        right: 0,
        width: "140px",
        textAlign: "right",
      },
    },
    certificateElement: {
      ...defaultComponentStyles.certificateElement,
      header: {
        position: "relative",
        paddingRight: "150px",
        marginBottom: styleConstants.spacing.small,
      },
      titleGroup: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "baseline",
      },
      name: {
        fontSize: styleConstants.fontSize.large,
        fontWeight: styleConstants.fontWeight.bold,
        marginBottom: 0,
        marginRight: "0.5em",
      },
      date: {
        position: "absolute",
        top: 0,
        right: 0,
        width: "140px",
        textAlign: "right",
      },
    },
    nameHeading: {
      ...defaultComponentStyles.nameHeading,
      caption: {
        ...defaultComponentStyles.nameHeading.caption,
        fontSize: styleConstants.fontSize.large,
        paddingTop: styleConstants.spacing.small,
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
      },
      firstName: { display: "inline" },
      lastName: { display: "inline", marginLeft: "0.5em" },
      container: {
        ...defaultComponentStyles.nameHeading.container,
        textAlign: "center",
      },
    },
  },
  dualColumn: {
    ...globalStyles,
    sectionHeader: {
      ...globalStyles.sectionHeader,
      borderBottom: `1px solid ${styleConstants.colors.primary}`,
    },
    ...defaultComponentStyles,
    skillsSection: {
      ...defaultComponentStyles.skillsSection,
      title: {
        ...defaultComponentStyles.skillsSection.title,
        borderBottom: `1px solid ${styleConstants.colors.primary}`,
      },
    },
    personalInfoElement: {
      ...defaultComponentStyles.personalInfoElement,
      title: {
        ...defaultComponentStyles.personalInfoElement.title,
        borderBottom: `1px solid ${styleConstants.colors.primary}`,
      },
    },
    nameHeading: {
      ...defaultComponentStyles.nameHeading,
      caption: {
        ...defaultComponentStyles.nameHeading.caption,
        backgroundColor: styleConstants.colors.secondary,
        marginTop: styleConstants.spacing.normal,
        padding: styleConstants.spacing.small,
      },
    },
    educationElement: {
      ...defaultComponentStyles.educationElement,
      courses: {
        display: "none",
      },
    },
  },
};

const hoverButtonStyle: React.CSSProperties = {
  position: "absolute",
  right: "0",
  bottom: "0",
  zIndex: 10,
};

const dummyResumeDate: ResumeDate = {
  kind: "YearMonth",
  year: 2000,
  month: 1,
};

function WorkElement({
  work,
  setWorkPosition,
  addWorkPosition,
  layout,
}: {
  work: Work;
  setWorkPosition: (work: Work) => void;
  addWorkPosition: (relativePosition: RelativePosition) => void;
  layout: ResumeLayout;
}): JSX.Element {
  const [isHovered, setIsHovered] = useState(false);
  const style = resumeStyles[layout].workElement;

  const onChangeWorkPosition = (value: string | null) => {
    let newWork = {
      ...work,
      position: value === null ? "" : value,
    };
    setWorkPosition(newWork);
  };

  const onChangeWorkName = (value: string | null) => {
    let newWork = {
      ...work,
      name: value === null ? "" : value,
    };
    setWorkPosition(newWork);
  };

  const onChangeWorkLocation = (value: string | null) => {
    let newWork = {
      ...work,
      location: value === null ? "" : value,
    };
    setWorkPosition(newWork);
  };

  const onChangeWorkHighlights = (value: string | null, i: number) => {
    let newHighlights = work.highlights.slice();
    if (value === null) {
      newHighlights.splice(i, 1);
    } else {
      newHighlights[i] = value;
    }
    let newWork = {
      ...work,
      highlights: newHighlights,
    };
    setWorkPosition(newWork);
  };

  const addWorkHighlight = (index: number) => {
    const newWorkHighlight = "Mustertätigkeit";
    let newHighlights = [...work.highlights];
    newHighlights.splice(index, 0, newWorkHighlight);
    let newWork = {
      ...work,
      highlights: newHighlights,
    };
    setWorkPosition(newWork);
  };

  return (
    <div
      style={style.container}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div style={style.header}>
        <div style={style.titleGroup}>
          <div style={style.position}>
            <EditableSpan
              value={work.position}
              multiline={true}
              placeholder={""}
              onChange={onChangeWorkPosition}
            />
            {isHovered && (
              <button
                onClick={() => addWorkPosition(RelativePosition.Before)}
                className={`btn btn-outline-dark ${NO_PRINT}`}
                style={hoverButtonStyle}
              >
                +
              </button>
            )}
          </div>
          <div>
            {work.name.trim() !== "" && (
              <div style={style.company}>
                <EditableSpan
                  value={work.name}
                  placeholder={""}
                  onChange={onChangeWorkName}
                />
              </div>
            )}
            {work.location.trim() !== "" && (
              <div style={style.location}>
                {work.name.trim() !== "" && ", "}
                <EditableSpan
                  value={work.location}
                  placeholder={""}
                  onChange={onChangeWorkLocation}
                />
              </div>
            )}
          </div>
        </div>
        <div style={style.date}>
          <EditableResumeDateRange
            startDate={work.startDate}
            endDate={work.endDate}
            onChangeStartDate={(date) => {
              setWorkPosition({
                ...work,
                startDate: date,
              });
            }}
            onChangeEndDate={(date) => {
              setWorkPosition({
                ...work,
                endDate: date,
              });
            }}
          />
        </div>
      </div>
      {work.highlights.length !== 0 && (
        <ul style={style.highlights}>
          {work.highlights
            .filter((highlight) => highlight.trim() !== "")
            .map((highlight, i) => (
              <li key={i}>
                <EditableSpan
                  value={highlight}
                  multiline={true}
                  placeholder={""}
                  onChange={(value) => {
                    onChangeWorkHighlights(value, i);
                  }}
                  onEnter={() => addWorkHighlight(i + 1)}
                />
              </li>
            ))}
        </ul>
      )}
    </div>
  );
}

function EducationElement({
  education,
  setEducation,
  addEducation,
  layout,
}: {
  education: Education;
  setEducation: (education: Education) => void;
  addEducation: (relativePosition: RelativePosition) => void;
  layout: ResumeLayout;
}): JSX.Element {
  const [isHovered, setIsHovered] = useState(false);
  const style = resumeStyles[layout].educationElement;

  const onChangeDegree = (value: string | null) => {
    setEducation({
      ...education,
      degree: value === null ? "" : value,
    });
  };

  const onChangeInstitution = (value: string | null) => {
    setEducation({
      ...education,
      institution: value === null ? "" : value,
    });
  };

  const onChangeScore = (value: string | null) => {
    setEducation({
      ...education,
      score: value === null ? "" : value,
    });
  };

  const onChangeCourse = (value: string | null, i: number) => {
    const newCourses = [...education.courses];
    if (value === null) {
      newCourses.splice(i, 1);
    } else {
      newCourses[i] = value;
    }
    setEducation({
      ...education,
      courses: newCourses,
    });
  };

  const addCourse = () => {
    setEducation({
      ...education,
      courses: [...education.courses, "New course"],
    });
  };

  return (
    <div
      style={style.container}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div style={style.header}>
        <div style={style.titleGroup}>
          <div style={style.degree}>
            <EditableSpan
              value={education.degree}
              placeholder={""}
              onChange={onChangeDegree}
            />
            {isHovered && (
              <button
                onClick={() => addEducation(RelativePosition.Before)}
                className={`btn btn-outline-dark ${NO_PRINT}`}
                style={hoverButtonStyle}
              >
                +
              </button>
            )}
          </div>
          {education.institution.trim() !== "" && (
            <div style={style.institution}>
              <EditableSpan
                value={education.institution}
                placeholder={""}
                onChange={onChangeInstitution}
              />
            </div>
          )}
        </div>
        <div style={style.date}>
          <EditableResumeDateRange
            startDate={education.startDate}
            endDate={education.endDate}
            onChangeStartDate={(date) => {
              setEducation({
                ...education,
                startDate: date,
              });
            }}
            onChangeEndDate={(date) => {
              setEducation({
                ...education,
                endDate: date,
              });
            }}
          />
        </div>
      </div>
      {education.score.trim() !== "" && (
        <div>
          Abschlussnote:{" "}
          <EditableSpan
            value={education.score}
            placeholder={""}
            onChange={onChangeScore}
          />
        </div>
      )}
      {education.courses.length !== 0 && (
        <ul style={style.courses}>
          {education.courses.map((course, i) => (
            <li key={i}>
              <EditableSpan
                value={course}
                placeholder={""}
                onChange={(value) => onChangeCourse(value, i)}
                onEnter={() => addCourse()}
              />
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

function SkillElement({
  skill,
  setSkill,
  layout,
}: {
  skill: Skill;
  setSkill: (skill: Skill) => void;
  layout: ResumeLayout;
}): JSX.Element {
  const style = resumeStyles[layout].skillElement;

  const onChangeName = (value: string | null) => {
    setSkill({
      ...skill,
      name: value === null ? "" : value,
    });
  };

  const onChangeProficiency = (value: string | null) => {
    setSkill({
      ...skill,
      proficiency: value === null ? "" : value,
    });
  };

  return (
    <div style={style.container}>
      <EditableSpan
        value={skill.name}
        placeholder={""}
        onChange={onChangeName}
      />
      {skill.proficiency.trim() !== "" && ": "}
      {skill.proficiency.trim() !== "" && (
        <EditableSpan
          value={skill.proficiency}
          placeholder={""}
          onChange={onChangeProficiency}
        />
      )}
    </div>
  );
}

function ContactsElement({
  jsonResume,
  setJsonResume,
  layout,
}: {
  jsonResume: JsonResume;
  setJsonResume: (newJsonResume: JsonResume) => void;
  layout: ResumeLayout;
}): JSX.Element | null {
  const { basics } = jsonResume;
  const style = resumeStyles[layout].contactsElement;

  const setBasics = (newBasics: Basics) => {
    setJsonResume({
      ...jsonResume,
      basics: newBasics,
    });
  };

  const onChangeEmail = (value: string | null) => {
    setBasics({
      ...basics,
      email: value === null ? "" : value,
    });
  };

  const onChangePhone = (value: string | null) => {
    setBasics({
      ...basics,
      phone: value === null ? "" : value,
    });
  };

  const onChangeCity = (value: string | null) => {
    setBasics({
      ...basics,
      location: {
        ...basics.location,
        city: value === null ? "" : value,
      },
    });
  };

  const onChangeStreetAddress = (value: string | null) => {
    setBasics({
      ...basics,
      location: {
        ...basics.location,
        streetAddress: value === null ? "" : value,
      },
    });
  };

  const onChangePostalCode = (value: string | null) => {
    setBasics({
      ...basics,
      location: {
        ...basics.location,
        postalCode: value === null ? "" : value,
      },
    });
  };

  const emailElement =
    basics.email === "" ? null : (
      <div style={style.row} key="email">
        <div style={style.icons}>
          <i className="bi bi-envelope"></i>
        </div>
        <EditableEmail
          value={basics.email}
          placeholder={""}
          onChange={onChangeEmail}
        />
      </div>
    );

  const phoneElement =
    basics.phone.trim() === "" || basics.phone.trim() === undefined ? null : (
      <div style={style.row} key="email">
        <div style={style.icons}>
          <i className="bi bi-telephone"></i>
        </div>
        <EditableSpan
          value={basics.phone}
          placeholder={""}
          onChange={onChangePhone}
        />
      </div>
    );

  const streetAddressElement =
    basics.location.streetAddress !== undefined &&
    basics.location.streetAddress.trim() !== "" ? (
      <>
        <EditableSpan
          value={basics.location.streetAddress}
          placeholder={""}
          onChange={onChangeStreetAddress}
        />
        <span>{", "}</span>
      </>
    ) : null;
  const postalCodeElement =
    basics.location.postalCode !== undefined &&
    basics.location.postalCode.trim() !== "" ? (
      <>
        <EditableSpan
          value={basics.location.postalCode}
          placeholder={""}
          onChange={onChangePostalCode}
        />
        <span> </span>
      </>
    ) : null;

  const cityElement =
    basics.location.city.trim() !== "" ? (
      <EditableSpan
        value={basics.location.city}
        placeholder={""}
        onChange={onChangeCity}
      />
    ) : null;

  const locationElements = [
    streetAddressElement,
    postalCodeElement,
    cityElement,
  ];
  const locationElement = locationElements.some(
    (element) => element != null,
  ) ? (
    <div style={style.row}>
      <div style={style.icons}>
        <i className="bi bi-geo-alt"></i>
      </div>
      <div style={style.locationElements}>{locationElements}</div>
    </div>
  ) : null;

  let elements: (JSX.Element | null)[] = [
    emailElement,
    phoneElement,
    locationElement,
  ];

  elements = elements.filter((element) => element != null);

  if (elements.length === 0) {
    return null;
  }

  return (
    <div style={style.container}>
      <h2 style={style.title}>Kontakt</h2>
      <div style={style.contentContainer}>
        {interleave(elements, style.separator)}
      </div>
    </div>
  );
}

function PersonalInfoElement({
  jsonResume,
  setJsonResume,
  layout,
}: {
  jsonResume: JsonResume;
  setJsonResume: (newJsonResume: JsonResume) => void;
  layout: ResumeLayout;
}): JSX.Element | null {
  const { personalInfo } = jsonResume;
  const style = resumeStyles[layout].personalInfoElement;

  const setPersonalInfo = (newPersonalInfo: PersonalInfo) => {
    setJsonResume({
      ...jsonResume,
      personalInfo: newPersonalInfo,
    });
  };

  const onChangeBirthDate = (value: ResumeDate | null) => {
    setPersonalInfo({
      ...personalInfo,
      birthDate: value,
    });
  };

  const onChangeBirthPlace = (value: string | null) => {
    setPersonalInfo({
      ...personalInfo,
      birthPlace: value === null ? "" : value,
    });
  };

  const onChangeNationality = (value: string | null) => {
    setPersonalInfo({
      ...personalInfo,
      nationality: value === null ? "" : value,
    });
  };

  const onChangeMaritalStatus = (value: string | null) => {
    setPersonalInfo({
      ...personalInfo,
      maritalStatus: value === null ? "" : value,
    });
  };

  const onChangeChildren = (value: string | null) => {
    setPersonalInfo({
      ...personalInfo,
      children: value === null ? "" : value,
    });
  };

  let elements = [
    personalInfo.birthDate === null ? null : (
      <div key="birthDate">
        Geburtsdatum:{" "}
        <EditableResumeDate
          value={personalInfo.birthDate}
          onChange={onChangeBirthDate}
        />
      </div>
    ),
    personalInfo.birthPlace === "" ? null : (
      <div key="birthPlace">
        Geburtsort:{" "}
        <EditableSpan
          value={personalInfo.birthPlace}
          placeholder={""}
          onChange={onChangeBirthPlace}
        />
      </div>
    ),
    personalInfo.nationality === "" ? null : (
      <div key="nationality">
        Nationalität:{" "}
        <EditableSpan
          value={personalInfo.nationality}
          placeholder={""}
          onChange={onChangeNationality}
        />
      </div>
    ),
    personalInfo.maritalStatus === "" ? null : (
      <div key="maritalStatus">
        Familienstand:{" "}
        <EditableSpan
          value={personalInfo.maritalStatus}
          placeholder={""}
          onChange={onChangeMaritalStatus}
        />
      </div>
    ),
    personalInfo.children === "" ? null : (
      <div key="children">
        Kinder:{" "}
        <EditableSpan
          value={personalInfo.children}
          placeholder={""}
          onChange={onChangeChildren}
        />
      </div>
    ),
  ];

  elements = elements.filter((element) => element != null);

  if (elements.length === 0) {
    return null;
  }

  return (
    <div style={style.container}>
      <h2 style={style.title}>Über mich</h2>
      <div style={style.contentContainer}>{elements}</div>
    </div>
  );
}

function SoftwareElement({
  software,
  setSoftware,
  layout,
}: {
  software: Skill[];
  setSoftware: (software: Skill[]) => void;
  layout: ResumeLayout;
}): JSX.Element {
  const style = resumeStyles[layout].softwareElement;
  return (
    <div style={style.container}>
      <h4 style={style.title}>Software</h4>
      <div style={style.contentContainer}>
        {software.map((softwareItem, i) => (
          <SkillElement
            key={i}
            skill={softwareItem}
            setSkill={(updatedSkill) => {
              const newSoftware = [...software];
              newSoftware[i] = updatedSkill;
              setSoftware(newSoftware);
            }}
            layout={layout}
          />
        ))}
      </div>
    </div>
  );
}

function LanguagesElement({
  languages,
  setLanguages,
  layout,
}: {
  languages: Skill[];
  setLanguages: (languages: Skill[]) => void;
  layout: ResumeLayout;
}): JSX.Element {
  const style = resumeStyles[layout].languagesElement;
  return (
    <div style={style.container}>
      <h4 style={style.title}>Sprachen</h4>
      <div style={style.contentContainer}>
        {languages.map((language, i) => (
          <SkillElement
            skill={language}
            key={i}
            setSkill={(updatedSkill) => {
              const newLanguages = [...languages];
              newLanguages[i] = updatedSkill;
              setLanguages(newLanguages);
            }}
            layout={layout}
          />
        ))}
      </div>
    </div>
  );
}

function DriversLicenceElement({
  driversLicence,
  setDriversLicence,
  layout,
}: {
  driversLicence?: string;
  setDriversLicence: (driversLicence: string) => void;
  layout: ResumeLayout;
}): JSX.Element {
  const style = resumeStyles[layout].driversLicenceElement;
  return (
    <div style={style.container}>
      <h4 style={style.title}>Führerschein</h4>
      <div style={style.contentContainer}>
        <EditableSpan
          value={driversLicence ?? ""}
          placeholder={""}
          onChange={(value) => setDriversLicence(value ?? "")}
        />
      </div>
    </div>
  );
}

function CharacterTraitsElement({
  characterTraits,
  setCharacterTraits,
  layout,
}: {
  characterTraits: string[];
  setCharacterTraits: (characterTraits: string[]) => void;
  layout: ResumeLayout;
}): JSX.Element {
  const style = resumeStyles[layout].characterTraitsElement;
  return (
    <div style={style.container}>
      <h4 style={style.title}>Fähigkeiten</h4>
      <div style={style.contentContainer}>
        {characterTraits.map((trait, i) => (
          <div key={i}>
            <EditableSpan
              value={trait}
              placeholder={""}
              onChange={(value) => {
                const newTraits = [...characterTraits];
                if (value === null) {
                  newTraits.splice(i, 1);
                } else {
                  newTraits[i] = value;
                }
                setCharacterTraits(newTraits);
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

function InterestsElement({
  interests,
  setInterests,
  layout,
}: {
  interests: string[];
  setInterests: (interests: string[]) => void;
  layout: ResumeLayout;
}): JSX.Element {
  const style = resumeStyles[layout].interestsElement;
  return (
    <div style={style.container}>
      <h4 style={style.title}>Interessen</h4>
      <div style={style.contentContainer}>
        {interests.map((interest, i) => (
          <div key={i}>
            <EditableSpan
              value={interest}
              placeholder={""}
              onChange={(value) => {
                const newInterests = [...interests];
                if (value === null) {
                  newInterests.splice(i, 1);
                } else {
                  newInterests[i] = value;
                }
                setInterests(newInterests);
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

function SkillsSection({
  jsonResume,
  setJsonResume,
  layout,
}: {
  jsonResume: JsonResume;
  setJsonResume: (newJsonResume: JsonResume) => void;
  layout: ResumeLayout;
}): JSX.Element | null {
  const { skills } = jsonResume;
  const style = resumeStyles[layout].skillsSection;

  const setSkills = (newSkills: Skills) => {
    setJsonResume({
      ...jsonResume,
      skills: newSkills,
    });
  };

  const elements: (JSX.Element | null)[] = [
    skills.software.length === 0 ? null : (
      <div style={style.skill} key="software">
        <SoftwareElement
          software={skills.software}
          setSoftware={(software) => setSkills({ ...skills, software })}
          layout={layout}
        />
      </div>
    ),
    skills.languages.length === 0 ? null : (
      <div style={style.skill} key="languages">
        <LanguagesElement
          languages={skills.languages}
          setLanguages={(languages) => setSkills({ ...skills, languages })}
          layout={layout}
        />
      </div>
    ),
    skills.driversLicence === "" ? null : (
      <div style={style.skill} key="driversLicence">
        <DriversLicenceElement
          driversLicence={skills.driversLicence}
          setDriversLicence={(driversLicence) =>
            setSkills({ ...skills, driversLicence })
          }
          layout={layout}
        />
      </div>
    ),
    skills.characterTraits.length === 0 ? null : (
      <div style={style.skill} key="characterTraits">
        <CharacterTraitsElement
          characterTraits={skills.characterTraits}
          setCharacterTraits={(characterTraits) =>
            setSkills({ ...skills, characterTraits })
          }
          layout={layout}
        />
      </div>
    ),
    skills.interests.length === 0 ? null : (
      <div style={style.skill} key="interests">
        <InterestsElement
          interests={skills.interests}
          setInterests={(interests) => setSkills({ ...skills, interests })}
          layout={layout}
        />
      </div>
    ),
  ];

  let presentElements = elements.filter((element) => element != null);

  if (presentElements.length === 0) {
    return null;
  }
  presentElements[0] = (
    <div>
      <h2 style={style.title}>Kenntnisse</h2>
      {presentElements[0]}
    </div>
  );

  return <>{interleave(presentElements, style.separator)}</>;
}

function VolunteerElement({
  volunteer,
  setVolunteer,
  addVolunteer,
  layout,
}: {
  volunteer: Volunteer;
  setVolunteer: (volunteer: Volunteer) => void;
  addVolunteer: (relativePosition: RelativePosition) => void;
  layout: ResumeLayout;
}): JSX.Element {
  const [isHovered, setIsHovered] = useState(false);
  const style = resumeStyles[layout].volunteerElement;

  const onChangePosition = (value: string | null) => {
    setVolunteer({
      ...volunteer,
      position: value === null ? "" : value,
    });
  };

  const onChangeOrganization = (value: string | null) => {
    setVolunteer({
      ...volunteer,
      organization: value === null ? "" : value,
    });
  };

  const onChangeHighlights = (value: string | null, i: number) => {
    let newHighlights = volunteer.highlights.slice();
    if (value === null) {
      newHighlights.splice(i, 1);
    } else {
      newHighlights[i] = value;
    }
    setVolunteer({
      ...volunteer,
      highlights: newHighlights,
    });
  };

  const addHighlight = (index: number) => {
    const newHighlight = "Mustertätigkeit";
    let newHighlights = [...volunteer.highlights];
    newHighlights.splice(index, 0, newHighlight);
    setVolunteer({
      ...volunteer,
      highlights: newHighlights,
    });
  };

  return (
    <div
      style={style.container}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div style={style.header}>
        <div style={style.titleGroup}>
          <div style={style.position}>
            <EditableSpan
              value={volunteer.position}
              multiline={true}
              placeholder={""}
              onChange={onChangePosition}
            />
            {isHovered && (
              <button
                onClick={() => addVolunteer(RelativePosition.Before)}
                className={`btn btn-outline-dark ${NO_PRINT}`}
                style={hoverButtonStyle}
              >
                +
              </button>
            )}
          </div>
          <div>
            {volunteer.organization.trim() !== "" && (
              <div style={style.organization}>
                <EditableSpan
                  value={volunteer.organization}
                  placeholder={""}
                  onChange={onChangeOrganization}
                />
              </div>
            )}
          </div>
        </div>
        <div style={style.date}>
          <EditableResumeDateRange
            startDate={volunteer.startDate}
            endDate={volunteer.endDate}
            onChangeStartDate={(date) => {
              setVolunteer({
                ...volunteer,
                startDate: date,
              });
            }}
            onChangeEndDate={(date) => {
              setVolunteer({
                ...volunteer,
                endDate: date,
              });
            }}
          />
        </div>
      </div>
      {volunteer.highlights.length !== 0 && (
        <ul style={style.highlights}>
          {volunteer.highlights
            .filter((highlight) => highlight.trim() !== "")
            .map((highlight, i) => (
              <li key={i}>
                <EditableSpan
                  value={highlight}
                  multiline={true}
                  placeholder={""}
                  onChange={(value) => {
                    onChangeHighlights(value, i);
                  }}
                  onEnter={() => addHighlight(i + 1)}
                />
              </li>
            ))}
        </ul>
      )}
    </div>
  );
}

function CertificateElement({
  certificate,
  setCertificate,
  addCertificate,
  layout,
}: {
  certificate: Certificate;
  setCertificate: (certificate: Certificate) => void;
  addCertificate: (relativePosition: RelativePosition) => void;
  layout: ResumeLayout;
}): JSX.Element {
  const [isHovered, setIsHovered] = useState(false);
  const style = resumeStyles[layout].certificateElement;

  const onChangeName = (value: string | null) => {
    setCertificate({
      ...certificate,
      name: value === null ? "" : value,
    });
  };

  const onChangeInstitution = (value: string | null) => {
    setCertificate({
      ...certificate,
      institution: value === null ? "" : value,
    });
  };

  const onChangeHighlights = (value: string | null, i: number) => {
    let newHighlights = certificate.highlights.slice();
    if (value === null) {
      newHighlights.splice(i, 1);
    } else {
      newHighlights[i] = value;
    }
    setCertificate({
      ...certificate,
      highlights: newHighlights,
    });
  };

  const addHighlight = (index: number) => {
    const newHighlight = "Musterinhalt";
    let newHighlights = [...certificate.highlights];
    newHighlights.splice(index, 0, newHighlight);
    setCertificate({
      ...certificate,
      highlights: newHighlights,
    });
  };

  return (
    <div
      style={style.container}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div style={style.header}>
        <div style={style.titleGroup}>
          <div style={style.name}>
            <EditableSpan
              value={certificate.name}
              multiline={true}
              placeholder={""}
              onChange={onChangeName}
            />
            {isHovered && (
              <button
                onClick={() => addCertificate(RelativePosition.Before)}
                className={`btn btn-outline-dark ${NO_PRINT}`}
                style={hoverButtonStyle}
              >
                +
              </button>
            )}
          </div>
          <div>
            {certificate.institution.trim() !== "" && (
              <div style={style.institution}>
                <EditableSpan
                  value={certificate.institution}
                  placeholder={""}
                  onChange={onChangeInstitution}
                />
              </div>
            )}
          </div>
        </div>
        <div style={style.date}>
          <EditableResumeDateRange
            startDate={certificate.startDate}
            endDate={certificate.endDate}
            onChangeStartDate={(date) => {
              setCertificate({
                ...certificate,
                startDate: date,
              });
            }}
            onChangeEndDate={(date) => {
              setCertificate({
                ...certificate,
                endDate: date,
              });
            }}
          />
        </div>
      </div>
      {certificate.highlights.length !== 0 && (
        <ul style={style.highlights}>
          {certificate.highlights
            .filter((highlight) => highlight.trim() !== "")
            .map((highlight, i) => (
              <li key={i}>
                <EditableSpan
                  value={highlight}
                  multiline={true}
                  placeholder={""}
                  onChange={(value) => {
                    onChangeHighlights(value, i);
                  }}
                  onEnter={() => addHighlight(i + 1)}
                />
              </li>
            ))}
        </ul>
      )}
    </div>
  );
}

function WorkSection({
  jsonResume,
  setJsonResume,
  layout,
}: {
  jsonResume: JsonResume;
  setJsonResume: (newJsonResume: JsonResume) => void;
  layout: ResumeLayout;
}): JSX.Element | null {
  if (
    jsonResume.work.filter(
      (elem) => elem.name.trim() !== "" && elem.position.trim() !== "",
    ).length === 0
  ) {
    return null;
  }

  const addWorkPosition = (
    relativePosition: RelativePosition,
    index: number,
  ) => {
    const newWork: Work = {
      name: "Musterfirma",
      position: "Musterposition",
      startDate: dummyResumeDate,
      endDate: dummyResumeDate,
      highlights: ["Mustertätigkeit 1"],
      location: "Musterstadt",
      url: "",
    };
    const newWorkList = [...jsonResume.work];
    newWorkList.splice(
      index + (relativePosition === RelativePosition.After ? 1 : 0),
      0,
      newWork,
    );
    setJsonResume({
      ...jsonResume,
      work: newWorkList,
    });
  };

  const setWorkPosition = (newWorkElem: Work, i: number) => {
    let newWorkElems = jsonResume.work.slice();
    newWorkElems[i] = newWorkElem;
    setJsonResume({
      ...jsonResume,
      work: newWorkElems,
    });
  };

  const style = resumeStyles[layout].workSection;

  const workElements = jsonResume.work.map((work: Work, i: number) => {
    const elem = (
      <WorkElement
        work={work}
        setWorkPosition={(work) => setWorkPosition(work, i)}
        addWorkPosition={(relativePosition) =>
          addWorkPosition(relativePosition, i)
        }
        layout={layout}
      />
    );
    if (i !== 0) {
      return elem;
    } else {
      return (
        <div>
          <h2 style={resumeStyles[layout].sectionHeader}>Berufserfahrung</h2>
          {elem}
        </div>
      );
    }
  });

  return <>{interleave(workElements, style.separator)}</>;
}

function EducationSection({
  jsonResume,
  setJsonResume,
  layout,
}: {
  jsonResume: JsonResume;
  setJsonResume: (newJsonResume: JsonResume) => void;
  layout: ResumeLayout;
}): JSX.Element | null {
  const style = resumeStyles[layout].educationSection;

  const addEducation = (relativePosition: RelativePosition, index: number) => {
    const newEducation: Education = {
      institution: "Musterinstitution",
      degree: "Musterabschluss",
      startDate: dummyResumeDate,
      endDate: dummyResumeDate,
      score: "2,0",
      url: "",
      courses: ["Musterkurs", "Musterinhalt"],
    };
    const newEducationList = [...jsonResume.education];
    newEducationList.splice(
      index + (relativePosition === RelativePosition.After ? 1 : 0),
      0,
      newEducation,
    );
    setJsonResume({
      ...jsonResume,
      education: newEducationList,
    });
  };

  const setEducation = (newEducation: Education, i: number) => {
    let newEducationElems = jsonResume.education.slice();
    newEducationElems[i] = newEducation;
    setJsonResume({
      ...jsonResume,
      education: newEducationElems,
    });
  };

  const educationElements = jsonResume.education.map((edu, i) => {
    const elem = (
      <EducationElement
        education={edu}
        setEducation={(education) => setEducation(education, i)}
        addEducation={(relativePosition) => addEducation(relativePosition, i)}
        layout={layout}
      />
    );
    if (i !== 0) {
      return elem;
    } else {
      return (
        <div>
          <h2 style={resumeStyles[layout].sectionHeader}>Ausbildung</h2>
          {elem}
        </div>
      );
    }
  });

  return <>{interleave(educationElements, style.separator)}</>;
}

function VolunteerSection({
  jsonResume,
  setJsonResume,
  layout,
}: {
  jsonResume: JsonResume;
  setJsonResume: (newJsonResume: JsonResume) => void;
  layout: ResumeLayout;
}): JSX.Element | null {
  if (
    jsonResume.volunteer.filter(
      (vol) => vol.organization.trim() !== "" || vol.position.trim() !== "",
    ).length === 0
  ) {
    return null;
  }

  const addVolunteer = (relativePosition: RelativePosition, index: number) => {
    const newVolunteer: Volunteer = {
      organization: "Musterorganisation",
      position: "Musterposition",
      startDate: dummyResumeDate,
      endDate: dummyResumeDate,
      url: "",
      highlights: ["Mustertätigkeit"],
    };
    const newVolunteerList = [...jsonResume.volunteer];
    newVolunteerList.splice(
      index + (relativePosition === RelativePosition.After ? 1 : 0),
      0,
      newVolunteer,
    );
    setJsonResume({
      ...jsonResume,
      volunteer: newVolunteerList,
    });
  };

  const setVolunteer = (newVolunteer: Volunteer, i: number) => {
    let newVolunteerElems = jsonResume.volunteer.slice();
    newVolunteerElems[i] = newVolunteer;
    setJsonResume({
      ...jsonResume,
      volunteer: newVolunteerElems,
    });
  };

  const style = resumeStyles[layout].volunteerSection;

  const volunteerElements = jsonResume.volunteer.map((vol, i) => {
    const elem = (
      <VolunteerElement
        volunteer={vol}
        setVolunteer={(volunteer) => setVolunteer(volunteer, i)}
        addVolunteer={(relativePosition) => addVolunteer(relativePosition, i)}
        layout={layout}
      />
    );
    if (i !== 0) {
      return elem;
    } else {
      return (
        <div>
          <h2 style={resumeStyles[layout].sectionHeader}>Ehrenamt</h2>
          {elem}
        </div>
      );
    }
  });

  return <>{interleave(volunteerElements, style.separator)}</>;
}

function CertificateSection({
  jsonResume,
  setJsonResume,
  layout,
}: {
  jsonResume: JsonResume;
  setJsonResume: (newJsonResume: JsonResume) => void;
  layout: ResumeLayout;
}): JSX.Element | null {
  if (
    jsonResume.certificates.filter(
      (cert) => cert.name.trim() !== "" || cert.institution.trim() !== "",
    ).length === 0
  ) {
    return null;
  }

  const addCertificate = (
    relativePosition: RelativePosition,
    index: number,
  ) => {
    const newCertificate: Certificate = {
      name: "Musterzertifikat",
      institution: "Musterinstitution",
      startDate: dummyResumeDate,
      endDate: dummyResumeDate,
      highlights: ["Musterinhalt"],
      url: "",
    };
    const newCertificateList = [...jsonResume.certificates];
    newCertificateList.splice(
      index + (relativePosition === RelativePosition.After ? 1 : 0),
      0,
      newCertificate,
    );
    setJsonResume({
      ...jsonResume,
      certificates: newCertificateList,
    });
  };

  const setCertificate = (newCertificate: Certificate, i: number) => {
    let newCertificateElems = jsonResume.certificates.slice();
    newCertificateElems[i] = newCertificate;
    setJsonResume({
      ...jsonResume,
      certificates: newCertificateElems,
    });
  };

  const style = resumeStyles[layout].certificateSection;

  const certificateElements = jsonResume.certificates.map((cert, i) => {
    const elem = (
      <CertificateElement
        certificate={cert}
        setCertificate={(certificate) => setCertificate(certificate, i)}
        addCertificate={(relativePosition) =>
          addCertificate(relativePosition, i)
        }
        layout={layout}
      />
    );
    if (i !== 0) {
      return elem;
    } else {
      return (
        <div>
          <h2 style={resumeStyles[layout].sectionHeader}>Weiterbildungen</h2>
          {elem}
        </div>
      );
    }
  });

  return <>{interleave(certificateElements, style.separator)}</>;
}

function NameHeading({
  jsonResume,
  setJsonResume,
  layout,
}: {
  jsonResume: JsonResume;
  setJsonResume: (newJsonResume: JsonResume) => void;
  layout: ResumeLayout;
}): JSX.Element {
  const { basics } = jsonResume;
  const { familyName, givenName, label } = basics;
  const style = resumeStyles[layout].nameHeading;

  const setGivenName = (value: string | null) => {
    setJsonResume({
      ...jsonResume,
      basics: { ...basics, givenName: value ?? "" },
    });
  };

  const setFamilyName = (value: string | null) => {
    setJsonResume({
      ...jsonResume,
      basics: { ...basics, familyName: value ?? "" },
    });
  };

  const setLabel = (value: string | null) => {
    setJsonResume({
      ...jsonResume,
      basics: { ...basics, label: value ?? "" },
    });
  };

  return (
    <div style={style.container}>
      <div style={style.name}>
        <AdjustableFontSizeContainer
          maxFontSize={styleConstants.fontSize.XXXXLargePx}
        >
          <div style={style.firstName}>
            <EditableSpan
              value={givenName}
              placeholder=""
              onChange={setGivenName}
            />
          </div>
          <div style={style.lastName}>
            <EditableSpan
              value={familyName}
              placeholder=""
              onChange={setFamilyName}
            />
          </div>
        </AdjustableFontSizeContainer>
      </div>
      {label && (
        <div style={style.caption}>
          <EditableSpan value={label} placeholder={""} onChange={setLabel} />
        </div>
      )}
    </div>
  );
}

type ResumeProps = {
  revisionId: string | null;
  pushApplicationParameters:
    | null
    | ((update: (old: ApplicationParameters) => ApplicationParameters) => void);
  layout: ResumeLayout;
};

function DualColumnResume({
  jsonResume,
  setJsonResume,
  revisionId,
}: {
  jsonResume: JsonResume;
  setJsonResume: (newJsonResume: JsonResume) => void;
  revisionId: string | null;
}) {
  const layout = "dualColumn";
  const style = resumeStyles[layout];

  const sectionProps: SectionProps = {
    jsonResume,
    setJsonResume,
    layout,
  };

  const sidebarSectionNames: Section[] = [
    "ContactsElement",
    "PersonalInfoElement",
    "SkillsSection",
  ];

  const contentSectionNames: Section[] = [
    "NameHeading",
    "WorkSection",
    "EducationSection",
    "VolunteerSection",
    "CertificateSection",
  ];

  const sidebarSectionSeparator = (
    <div style={{ height: styleConstants.spacing.large }} />
  );

  const contentSectionSeparator = (
    <div style={{ height: styleConstants.spacing.large }} />
  );

  const sidebarSections = renderSections(
    sidebarSectionNames,
    sectionProps,
    sidebarSectionSeparator,
  );
  const contentSections = renderSections(
    contentSectionNames,
    sectionProps,
    contentSectionSeparator,
  );

  return (
    <>
      <DualColumnTitlePage
        jsonResume={jsonResume}
        setJsonResume={setJsonResume}
        layout={layout}
        revisionId={revisionId}
      />
      <div style={style.resume}>
        <div style={style.sidebar}>
          {revisionId != null && (
            <Headshot revisionId={revisionId} style={style.portrait} />
          )}

          <div className={MULTI_PAGE} style={style.sidebarInner}>
            <div style={{ height: "15mm" }}></div>
            {sidebarSections}
          </div>
        </div>

        <div style={style.content}>
          <div className={MULTI_PAGE} style={style.contentInner}>
            {contentSections}
          </div>
        </div>
      </div>
    </>
  );
}

function DualColumnPageLayout({
  sidebarInnerChildren,
  contentInnerChildren,
}: {
  sidebarInnerChildren: JSX.Element[];
  contentInnerChildren: JSX.Element[];
}): JSX.Element {
  const style = resumeStyles["dualColumn"];
  return (
    <div style={style.resume}>
      <div style={style.sidebar}>
        <div className={MULTI_PAGE} style={style.sidebarInner}>
          {sidebarInnerChildren}
        </div>
      </div>

      <div style={style.content}>
        <div className={MULTI_PAGE} style={style.contentInner}>
          {contentInnerChildren}
        </div>
      </div>
    </div>
  );
}

function renderSections(
  sections: Section[],
  sectionProps: SectionProps,
  separator: JSX.Element,
): JSX.Element[] {
  const filteredSections: Section[] = sections.filter((section: Section) =>
    sectionsMap[section].shouldRender(sectionProps.jsonResume),
  );

  const renderedSections = filteredSections.map((section: Section) => {
    return sectionsMap[section].component(sectionProps);
  });

  const interleavedSections = interleave(renderedSections, separator);

  return interleavedSections;
}

function SingleColumnResume({
  jsonResume,
  setJsonResume,
  revisionId,
}: {
  jsonResume: JsonResume;
  setJsonResume: (newJsonResume: JsonResume) => void;
  revisionId: string | null;
}) {
  const layout = "singleColumn";
  const style = resumeStyles[layout];

  const sectionNames: Section[] = [
    "NameHeading",
    "ContactsElement",
    "WorkSection",
    "EducationSection",
    "CertificateSection",
    "SkillsSection",
    "VolunteerSection",
  ];

  const sectionProps: SectionProps = {
    jsonResume,
    setJsonResume,
    layout,
  };

  const separatorMargin = styleConstants.spacing.normalLarge;
  const separator = (
    <hr
      style={{
        marginTop: separatorMargin,
        marginBottom: separatorMargin,
      }}
      className={HIDE_AT_PAGE_BREAK}
    />
  );

  const sections = renderSections(sectionNames, sectionProps, separator);

  return (
    <div style={{ ...style.resume, flexDirection: "column" }}>
      <div style={style.content}>
        <div className={MULTI_PAGE} style={style.contentInner}>
          {sections}
        </div>
      </div>
    </div>
  );
}

export function Resume({
  revisionId,
  pushApplicationParameters,
  layout,
}: ResumeProps): JSX.Element | null {
  const [jsonResume, setJsonResume] = useState<JsonResume | null>(null);
  const [loading, setLoading] = useState(false);

  const setAndPushJsonResume = useCallback(
    function setAndPushJsonResume(newJsonResume: JsonResume): void {
      setJsonResume(newJsonResume);
      if (pushApplicationParameters !== null) {
        pushApplicationParameters((old: ApplicationParameters) => ({
          ...old,
          jsonResume: newJsonResume,
        }));
      }
    },
    [setJsonResume, pushApplicationParameters],
  );

  useEffect(() => {
    (async function () {
      if (revisionId != null) {
        setLoading(true);
        try {
          const jsonResume = await getJsonResume({ revisionId });
          setJsonResume(jsonResume);
        } catch (e) {
          console.error(e);
        } finally {
          setLoading(false);
        }
      }
    })();
  }, [revisionId, setAndPushJsonResume]);

  if (loading) {
    return <Spinner />;
  } else if (jsonResume == null) {
    return null;
  }

  switch (layout) {
    case "dualColumn":
      return (
        <DualColumnResume
          jsonResume={jsonResume}
          setJsonResume={setAndPushJsonResume}
          revisionId={revisionId}
        />
      );
    case "singleColumn":
      return (
        <SingleColumnResume
          jsonResume={jsonResume}
          setJsonResume={setAndPushJsonResume}
          revisionId={revisionId}
        />
      );
    default:
      return null;
  }
}

function DualColumnTitlePage({
  jsonResume,
  setJsonResume,
  layout,
  revisionId,
}: {
  jsonResume: JsonResume;
  setJsonResume: (newJsonResume: JsonResume) => void;
  layout: ResumeLayout;
  revisionId: string | null;
}): JSX.Element | null {
  if (revisionId == null) {
    return null;
  }
  // TODO: calculate the dimensions s.t. there is a max height
  // and the aspect ratio is preserved.
  // Ideally, we simultaneously manage to center
  // the image around the column split properly.
  // Easy hack as alternative: just fix the height?
  const style_portrait: React.CSSProperties = {
    position: "absolute",
    top: "15mm",
    left: "20mm",
    width: "90mm",
    height: "auto",
  };

  const pageContainerStyle: React.CSSProperties = {
    height: `${A4_PAGE_HEIGHT_INNER_PX}px`,
    width: "100%",
    position: "relative",
  };
  const contactsElement = (
    <div
      style={{
        position: "absolute",
        bottom: 0,
      }}
    >
      <ContactsElement
        jsonResume={jsonResume}
        setJsonResume={setJsonResume}
        layout={layout}
      />
    </div>
  );

  const sidebarPage = (
    <div style={pageContainerStyle}>
      <div style={style_portrait}>
        <Headshot
          revisionId={revisionId}
          style={{ width: "100%", height: "auto" }}
        />
      </div>
      {contactsElement}
    </div>
  );

  const nameHeading = (
    <div
      style={{
        position: "absolute",
        top: "55%",
        width: "100%",
      }}
    >
      <NameHeading
        jsonResume={jsonResume}
        setJsonResume={setJsonResume}
        layout={layout}
      />
      <hr
        style={{
          marginTop: styleConstants.spacing.normalLarge,
          marginBottom: styleConstants.spacing.normalLarge,
        }}
      />
      <ApplicationTitle revisionId={revisionId} />
    </div>
  );

  const contentPage = <div style={pageContainerStyle}>{nameHeading}</div>;

  const sidebarInnerChildren: JSX.Element[] = [sidebarPage];
  const contentInnerChildren: JSX.Element[] = [contentPage];
  return (
    <DualColumnPageLayout
      sidebarInnerChildren={sidebarInnerChildren}
      contentInnerChildren={contentInnerChildren}
    />
  );
}

function ApplicationTitle({ revisionId }: { revisionId: string }): JSX.Element {
  const title = useApplicationTitle(revisionId);
  const style: React.CSSProperties = {
    fontSize: styleConstants.fontSize.XXXLargePx,
  };
  return <div style={style}>{title}</div>;
}
